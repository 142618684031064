<template>
  <div class="list">
    <div class="header">
      <h3 class="sub-title">
        {{title}}
      </h3>
      <div class="button-box">
        <!-- <v-btn
          color="white"
          class="red--text"
        >
        <v-icon
          left
          dark
        >
          mdi-microsoft-excel
        </v-icon>
          Сформировать отчет
        </v-btn> -->
      </div>
    </div>
    <div class="responsive">
      <v-skeleton-loader
       v-if="!load"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table table-no-link" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              {{header.text}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(table, index) in getReports"
            :key="index"
          >
          <td class="text-center" v-if="table.code" :rowspan="table.rows">{{table.code}}</td>
          <td class="text-center name" v-if="table.standartName" :rowspan="table.rows">{{table.standartName}}</td>
          <td class="text-center" v-if="table.clinrec" :rowspan="table.rows">{{table.clinrec}}</td>
            <td class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                  v-bind="attrs"
                  v-on="on">{{table.depNumber}}</span>
                </template>
                <span>{{table.department}}</span>
              </v-tooltip>
            </td>
            <td class="text-center">{{table.fullName == null ? "-" : table.fullName}}</td>
            <td class="text-center">
              {{table.voteStatus == 0 ? '-' : table.voteStatus == 1 ? 'Согласовано' : 'Отклонено'}}
            </td>
            <td class="text-center">
              {{table.setDate == null ? '-' : dataItem(table.setDate)}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'
  import moment from 'moment'
  export default {
    name: 'ListBlood',
    data () {
      return {
        title: 'Отчет по результатом согласования проектов',
        load: false,
        headers: [
          {text: 'Код СМП'},
          {text: 'Наименование стандарта'},
          {text: 'Клиническая рекомендация'},
          {text: 'Департамент'},
          {text: 'ФИО согласующего'},
          {text: 'Оценка'},
          {text: 'Дата оценки'}
          // {text: 'Параметр'},
          // {text: 'Обоснование изменение'},
          // {text: 'Предлагаемое значение'},
          // {text: 'Дата добавления замечания'}
        ],
      }
    },
    computed : {
      ...mapGetters({
          getReports: 'reports/getReports',
      }),
    },
    methods: {
      ...mapActions({
          reports: 'reports/reports',
      }),
      ...mapMutations({
          setSearch: 'reports/setSearch',
      }),
      dataItem(data) {
        return moment(data).format('DD.MM.YYYY')
      }
    },
    mounted() {
      this.reports('reconciliation').then(() => this.load = true)
    }
  }
</script>
